import React from "react"

export default function FunctionGuestSelectionOptions() {
  return (
    <>
      <option value="0">Just me</option>
      <option value="1">With 1 guest</option>
      <option value="2">With 2 guests</option>
      <option value="3">With 3 guests</option>
      <option value="4">With 4 guests</option>
      <option value="5">With 5 guests</option>
    </>
  )
}
