import React from "react"

export default function Agreements({ status }) {
  return (
    <>
      <p>
        {status === "beforeSubmit" ? (
          <>By submitting your entry, you agree</>
        ) : (
          <>You agree</>
        )}
        <br />
        - to abide by all tournament entry conditions and decisions by the
        tournament committee
        <br />- to receive emails and text messages from QMSA
        <br />- for QMSA to take photos of you, and your photos to appear on our websites,
        social media, and promotional materials
        <br />- to adhere to the{" "}
        <a href="https://ourvoice.qmsa.asn.au/code-of-conduct/">
          QMSA Code of Conduct
        </a>
        <br />- to play in the{" "}
        <a href="https://ourvoice.qmsa.asn.au/spirit-of-the-masters/">
          Spirit of the Masters
        </a>
        <br />
        Note: QMSA does not hold Public Liability Insurance
      </p>
    </>
  )
}
