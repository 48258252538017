export function submitEntry(
  entryId,
  tournamentName,
  playerName,
  playerPhone,
  playerEmail,
  playerClubName,
  entryNotes
) {
  const submitTime = new Date()

  return fetch(
    "https://e3hrge7vah.execute-api.ap-southeast-2.amazonaws.com/prod/submitentry",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        TournamentName: tournamentName,
        PlayerName: playerName,
        PlayerPhone: playerPhone,
        PlayerEmail: playerEmail,
        PlayerClubName: playerClubName,
        EntryId: entryId,
        EntrySubmitTime: submitTime,
        EntryNotes: entryNotes,
      }),
    }
  ).then(data => data.json())
}
