import React from "react"

export default function EventGympieSelectionOptions() {
  return (
    <>
        <option value="">-- Choose an event to enter --</option>
        <option value="Singles & Doubles (Fri, Sat & Sun)">Singles & Doubles (Fri, Sat & Sun)</option>
        <option value="Singles (Sat & Sun)">Singles (Sat & Sun)</option>
        <option value="Doubles (Fri evening) only">Doubles (Fri evening) only</option>
    </>
  )
}
